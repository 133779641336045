import { default as _91_46_46_46slug_93pvGVZEAWcBMeta } from "/usr/src/app/pages/[...slug].vue?macro=true";
import { default as index6LTnG94DlyMeta } from "/usr/src/app/pages/account/index.vue?macro=true";
import { default as _91id_93COwJFbdOzSMeta } from "/usr/src/app/pages/account/orders/[id].vue?macro=true";
import { default as welcomeHragZC5iYSMeta } from "/usr/src/app/pages/account/welcome.vue?macro=true";
import { default as _91brand_93Nx9PDKwPEAMeta } from "/usr/src/app/pages/brands/[brand].vue?macro=true";
import { default as indexnAn0aWyQk5Meta } from "/usr/src/app/pages/cart/index.vue?macro=true";
import { default as _91state_93dAkBXwuDapMeta } from "/usr/src/app/pages/checkout/callback/[fingerprint]/[state].vue?macro=true";
import { default as cancelX7IL3GSuxtMeta } from "/usr/src/app/pages/checkout/cancel.vue?macro=true";
import { default as completeGn7e3czVRVMeta } from "/usr/src/app/pages/checkout/complete.vue?macro=true";
import { default as fail5xq2RfbHyMMeta } from "/usr/src/app/pages/checkout/fail.vue?macro=true";
import { default as indexJPEr6nErnHMeta } from "/usr/src/app/pages/checkout/index.vue?macro=true";
import { default as _91fingerprint_93noCFRV3HmvMeta } from "/usr/src/app/pages/checkout/redirect/[fingerprint].vue?macro=true";
import { default as refreshdl4pmK5l7IMeta } from "/usr/src/app/pages/checkout/refresh.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as _91outlet_93g8qC4hpOQ3Meta } from "/usr/src/app/pages/outlets/[outlet].vue?macro=true";
import { default as indexyo2ME00pYYMeta } from "/usr/src/app/pages/outlets/index.vue?macro=true";
import { default as _91product_93FSx9TqsowlMeta } from "/usr/src/app/pages/products/[product].vue?macro=true";
import { default as indextGTcZpyi4NMeta } from "/usr/src/app/pages/products/index.vue?macro=true";
import { default as indexUzt6YGbMnbMeta } from "/usr/src/app/pages/search/index.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/usr/src/app/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "account",
    path: "/account",
    meta: index6LTnG94DlyMeta || {},
    alias: ["/profiili"],
    component: () => import("/usr/src/app/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: "account-orders-id",
    path: "/account/orders/:id()",
    meta: _91id_93COwJFbdOzSMeta || {},
    component: () => import("/usr/src/app/pages/account/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: "account-welcome",
    path: "/account/welcome",
    meta: welcomeHragZC5iYSMeta || {},
    component: () => import("/usr/src/app/pages/account/welcome.vue").then(m => m.default || m)
  },
  {
    name: "brands-brand",
    path: "/brands/:brand()",
    component: () => import("/usr/src/app/pages/brands/[brand].vue").then(m => m.default || m)
  },
  {
    name: "cart",
    path: "/cart",
    component: () => import("/usr/src/app/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-callback-fingerprint-state",
    path: "/checkout/callback/:fingerprint()/:state()",
    meta: _91state_93dAkBXwuDapMeta || {},
    component: () => import("/usr/src/app/pages/checkout/callback/[fingerprint]/[state].vue").then(m => m.default || m)
  },
  {
    name: "checkout-cancel",
    path: "/checkout/cancel",
    meta: cancelX7IL3GSuxtMeta || {},
    component: () => import("/usr/src/app/pages/checkout/cancel.vue").then(m => m.default || m)
  },
  {
    name: "checkout-complete",
    path: "/checkout/complete",
    meta: completeGn7e3czVRVMeta || {},
    component: () => import("/usr/src/app/pages/checkout/complete.vue").then(m => m.default || m)
  },
  {
    name: "checkout-fail",
    path: "/checkout/fail",
    meta: fail5xq2RfbHyMMeta || {},
    component: () => import("/usr/src/app/pages/checkout/fail.vue").then(m => m.default || m)
  },
  {
    name: "checkout",
    path: "/checkout",
    meta: indexJPEr6nErnHMeta || {},
    component: () => import("/usr/src/app/pages/checkout/index.vue").then(m => m.default || m)
  },
  {
    name: "checkout-redirect-fingerprint",
    path: "/checkout/redirect/:fingerprint()",
    meta: _91fingerprint_93noCFRV3HmvMeta || {},
    component: () => import("/usr/src/app/pages/checkout/redirect/[fingerprint].vue").then(m => m.default || m)
  },
  {
    name: "checkout-refresh",
    path: "/checkout/refresh",
    meta: refreshdl4pmK5l7IMeta || {},
    component: () => import("/usr/src/app/pages/checkout/refresh.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "outlets-outlet",
    path: "/outlets/:outlet()",
    meta: _91outlet_93g8qC4hpOQ3Meta || {},
    alias: ["/kauplused/:outlet","/myymalat/:outlet"],
    component: () => import("/usr/src/app/pages/outlets/[outlet].vue").then(m => m.default || m)
  },
  {
    name: "outlets",
    path: "/outlets",
    meta: indexyo2ME00pYYMeta || {},
    alias: ["/kauplused/","/myymalat/"],
    component: () => import("/usr/src/app/pages/outlets/index.vue").then(m => m.default || m)
  },
  {
    name: "products-product",
    path: "/products/:product()",
    meta: _91product_93FSx9TqsowlMeta || {},
    alias: ["/tuote/:product","/toote/:product","/toode/:product"],
    component: () => import("/usr/src/app/pages/products/[product].vue").then(m => m.default || m)
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/usr/src/app/pages/products/index.vue").then(m => m.default || m)
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/usr/src/app/pages/search/index.vue").then(m => m.default || m)
  }
]